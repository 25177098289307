import logo from './logo.svg';
import {
  Button
} from '@mui/material';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
        <div>
          <Button
            type='submit'
            align='center'
            variant='contained'
            color='primary'
            onClick={() => { alert('Realizando un test') }}>LogIn
          </Button>
        </div>
      </header>
    </div>
  );
}

export default App;
